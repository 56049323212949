const csvFile = {};

csvFile.create = function (data) {
	const csvContent = 'data:text/csv;charset=utf-8,' + data.map(e => e.join(',')).join('\n');
	const encodedUri = encodeURI(csvContent);

	return encodedUri;
};

module.exports = csvFile;
